/**
 * Gutenberg Blocks (Front-End)
 *
 * All blocks front-end related JavaScript files should be imported here.
 */
import Splide from '@splidejs/splide';

var elementExists = document.querySelector('.splide') !== null;

if (elementExists) {
    var splide = new Splide( '.splide', {
      speed: 1000,
      arrowPath: 'M6 20C6 19.5454 6.17777 19.1095 6.4942 18.788C6.81063 18.4666 7.23981 18.286 7.68731 18.286H27.2365L19.9912 10.9296C19.6744 10.6078 19.4964 10.1713 19.4964 9.71614C19.4964 9.26099 19.6744 8.82449 19.9912 8.50265C20.308 8.18081 20.7377 8 21.1858 8C21.6339 8 22.0636 8.18081 22.3804 8.50265L32.5043 18.7865C32.6614 18.9457 32.7861 19.1349 32.8712 19.3431C32.9562 19.5513 33 19.7746 33 20C33 20.2254 32.9562 20.4487 32.8712 20.6569C32.7861 20.8651 32.6614 21.0543 32.5043 21.2135L22.3804 31.4974C22.0636 31.8192 21.6339 32 21.1858 32C20.7377 32 20.308 31.8192 19.9912 31.4974C19.6744 31.1755 19.4964 30.739 19.4964 30.2839C19.4964 29.8287 19.6744 29.3922 19.9912 29.0704L27.2365 21.714H7.68731C7.23981 21.714 6.81063 21.5334 6.4942 21.212C6.17777 20.8905 6 20.4546 6 20Z',
    } );
    splide.mount();
}
